// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

@import "./helpers";

// Variables
@import "variables";

/////////// Plugins ///////////

@import "./plugins/snotify/custom-material";

@import "./../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "./plugins/multiselect/style";

// Functions
@mixin btn($color, $bg-color, $hover-color, $hover-bg-color){
  color: $color;
  background-color: $bg-color;
  border-color: $bg-color;
  .material-icons{
    vertical-align: middle;
  }
  &:hover, &:active{
    color: $hover-color !important;
    background-color: $hover-bg-color !important;
    border-color: $hover-bg-color !important;
  }
  &:active:focus{
    box-shadow: none !important;
  }
}
@mixin links ($color, $hover-color){
  color: $color !important;
  &:hover{
    color: $hover-color !important;
  }
}
@mixin links-div($color, $hover-color){
  transition: background-color 0.5s ease;
  background-color: $color !important;
  &:hover{
    transition: background-color 0.5s ease;
    background-color: $hover-color!important;
  }
}
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
@mixin shadow {
  box-shadow: 2px 2px 15px 1px rgba(0,0,0,.1) !important;
}
@mixin rounded{
  border-radius: $round!important;
}
@mixin rounded-ind($round-top-left, $round-top-right, $round-bottom-right, $round-bottom-left){
  border-radius: $round-top-left $round-top-right $round-bottom-right $round-bottom-left!important;
}

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap.scss";

// Reset Styles
select:focus, a:focus, .button:focus, input:focus, button:focus{
  border-color: inherit;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

// Body
html body{
  font-family: 'Lato', sans-serif;
  background-color: $gray-100;
  font-size: $font-size-md;
  color: $gray-600;
  font-weight: 400;
}
ul{
  padding-left: 0;
  list-style: none;
}
a{
  transition: color 0.3s ease;
  &:hover{
    transition: color 0.3s ease;
  }
}

// Font Size
.font-sm{
  font-size: $font-size-sm;
  font-weight: 400;
}
.font-md{
  font-size: $font-size-md;
}
.font-lg{
  font-size: $font-size-lg;
  letter-spacing: .1rem;
}
.font-xl{
  font-size: $font-size-xl;
  letter-spacing: .3rem;
}

// h1, h2, h3, h4, h5
h1, h2, h3{
  color: $gray-800;
  font-weight: bold;
  line-height: 1.5;
}

// Colors
.blue{
  color: $blue;
}
.white{
  color: $white !important;
}

// Forms
form, .section-filter, div{
  .form-group{
    label{
      margin-bottom: .4rem;
    }
    .input-style{
      height: 50px !important;
      border: 1px solid $gray-400;
      padding: 0 10px 0 45px;
      font-weight: 400;
      &:focus{
        border: 3px solid $blue;
        padding: 0 8px 0 43px;
        & + .btn-append{
          /*border-top: 1px solid $blue;
          right: 16px;
          top: 32px;
          height: 44px;*/
        }
      }
      &:-moz-read-only { /* For Firefox */
        background-color: transparent !important;
      }
      &:read-only {
        background-color: transparent !important;
      }
    }
    .input-style-sm {
      padding: 4px 10px 4px 26px;
      height: auto !important;
      &:focus{
        padding: 2px 8px 2px 24px;
      }
    }
    .input-style-open-dialog{
      padding: 12px 10px 0 15px;
      &:after{
        height: auto;
        padding: 11px 17px;
      }
    }
    select.input-style{
      cursor: pointer;
      appearance: auto;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    .select_arrow_down {
      left: auto !important;
      right: 20px;
    }
    textarea.input-style{
      height: auto !important;
      padding: 10px;
      &:focus{
        padding: 8px;
      }
    }
    & > .material-icons{
      position: absolute;
      left: 28px;
      top: 43px;
      font-size: 24px;
      pointer-events: none;
    }
    & > .material-icons-sm{
      position: absolute;
      left: 20px;
      top: 36px;
      font-size: 18px;
      pointer-events: none;
    }
    .material-icons-footer{
      top: 15px;
    }
    .input-group > .input-group-prepend .material-icons {
      position: absolute;
      left: 10.5px;
      top: 13px;
      font-size: 24px;
    }
    .input-style-multiples-elements{
      height: auto !important;
      padding: 5px;
      div{
        background-color: $gray-300;
        padding: 2px 15px;
        border-radius: 15px;
        margin: 3px 0;
        max-width: max-content;
        overflow: hidden;
        position: relative;
        .material-icons{
          position: relative;
          left: 0;
          top: 3px;
          font-size: 20px;
          margin-right: 5px;
        }
        .input-style-multiples-elements-cancel{
          margin-right: 0;
          margin-left: -40px;
          width: 40px;
          top: 0;
          text-align: right;
          padding-top: 2px;
          cursor: pointer;
          background: linear-gradient(to right,rgba( 222, 226, 230,0) 0, $gray-300 45%, $gray-300 100%);
        }
      }
      span{
        float: left;
      }
    }
    .btn-append{
      position: absolute;
      right: 16px;
      top: 30px;
      height: 48px;
      padding: 0.375rem 0.75rem !important;
      color: #495057;
      background-color: #e9ecef;
      border-left: 1px solid #ced4da;
      border-radius: 0 0.25rem 0.25rem 0;
    }
    .btn-append-before{
      right: 88px;
      border-radius: 0;
    }
  }
  .form-control{
    &, &:focus{
      background-color: transparent;
      box-shadow: none;
    }
  }
  .inside-submit{
    position: absolute;
    right: 18px;
    top: 8px;
    border: none;
  }
  .form-inc-dec{
    margin-top: 20px;
    input{
      height: 50px !important;
      border: 1px solid $gray-400;
      padding: 0 10px;
      font-weight: 400;
      background-color: transparent;
      width: 100px;
      text-align: center;
      &:focus{
        border: 3px solid $blue;
        padding: 0 8px;
      }
    }
    label{
      margin-right: 5px;
    }
    a{
      margin: 0 5px;
    }
  }
  @include placeholder {
    opacity: .5 !important;
  }
  .form-group-multi{
    .multiselect__tag{
      background-color: $gray-300;
      padding: 2px 40px 2px 15px;
      border-radius: 15px;
      margin: 3px 5px;
      max-width: max-content;
      overflow: hidden;
      position: relative;
      .multiselect__tag-icon{
        margin-right: 0;
        margin-left: -15px;
        width: 35px;
        top: 0;
        text-align: right;
        padding-top: 2px;
        cursor: pointer;
        right: 17px;
        font-weight: 400;
        background: linear-gradient(to right, rgba(222, 226, 230, 0) 0, #dee2e6 45%, #dee2e6 100%);
        &:after{
          color: #495057;
          font-size: 25px;
        }
        &:hover{
          color: #495057;
        }
      }
    }
    .multiselect__input{
      margin: 10px 0;
    }
  }
  .form-group-multi-2{
    .material-icons{
      z-index: 51;
    }
    .multiselect__tag{
      background-color: $gray-300;
      padding: 2px 40px 2px 15px;
      border-radius: 15px;
      margin: 3px 5px;
      max-width: max-content;
      overflow: hidden;
      position: relative;
    }
    .multiselect__tags{
      padding-left: 37px;
      padding-top: 8px !important;
    }
    .multiselect__input, .multiselect__single{
      margin: 6px 0;
    }
  }
  .multiselect__tags, .multiselect, .multiselect__select{
    max-height: inherit !important;
    min-height: 50px !important;
  }
}
.quote-form-cont{
  @include media-breakpoint-up(sm){
    @include shadow;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    .box-element-info{
      padding: 1.6rem;
    }
  }
}
.quote-form{
  .quote-label-dates{
    width: calc(50% - 10px);
  }
  .quote-input-dates{
    padding: 10px 0 0 0 !important;
    .quote-input-dates-cont{
      width: calc(50% - 10px);
      position: relative;
      padding-left: 45px;
      .material-icons{
        left: 10px;
        top: 0;
      }
      @include media-breakpoint-down(sm){
        padding-left: 15px;
      }
    }
    .quote-input-dates-cont-1{
      border-right: 1px solid $gray-400;
    }
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }
}
.popover-container{
  .c-title{
    font-size: 1rem !important;
    font-weight: bold !important;
  }
  .c-day-layer{
    .c-day-background{
      background-color: $color-blue !important;
      border-style: none !important;
      color: $white !important;
    }
  }
}

// Btns
.btn{
  border: none;
  padding: .7rem 2em !important;
}
.btn:focus{
  border: none;
  box-shadow: none;
}
.btn-action{
  @include btn($white, $color-orange, $white, $color-orange-dark);
}
.btn-bucket{
  @include btn($gray-600, $white, $gray-600, $gray-100);
  @include shadow;
  &:hover, &:active{
    .material-icons{
      color: $red;
    }
  }
}
.btn-bucket-active{
  .material-icons{
    color: $red;
  }
}
.link-bucket .material-icons{
  color: $gray-600;
  &:hover, &:active{
    color: $red;
  }
}
.link-bucket-active .material-icons{
  color: $red;
}
.btn-lg{
  padding: 0.7rem 2.4em;
  font-size: $font-size-sm;
  font-weight: bold;
  letter-spacing: $default-letter-spacing;
}
.icon-action{
  .material-icons{
    color: $color-orange;
  }
  &:hover, &:active{
    .material-icons{
      color: $color-orange-dark;
    }
  }
}
.btn-xs-100{
  @include media-breakpoint-down(xs){
    width: 100%;
  }
}

// Toggle Switch
.switch{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-bottom: -5px;
  input {
    display: none;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before{
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}

// Loader
.loader {
  position: relative;
  margin: 0 auto;
  width: 100px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}
@keyframes color {
  100%,
  0% {
    stroke: $white;
  }
}
.loader-gray-500 {
  @keyframes color {
    100%,
    0% {
      stroke: $gray-500;
    }
  }
}

// Important Classes
.box-shadow{
  @include shadow;
}
.box-round{
  border-radius: 0.5rem;
}
.col-1-space{
  @include media-breakpoint-up(md){
    padding-right: 3rem;
    border-right: 1px solid $gray-300;
  }
}
.col-2-space{
  @include media-breakpoint-up(md){
    padding-left: 3rem;
  }
  ul{
    li{
      margin-bottom: .2rem;
      &:before{
        content: 'check';
        font-family: 'Material Icons';
        margin-right: .8rem;
        vertical-align: middle;
        font-size: $font-size-md;
      }
    }
  }
}
.controllers-cont{
  height: 150px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(to bottom,rgba(0,0,0,0),$black);
  .video-controllers{
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
  .next-section-anchor{
    position: absolute;
    left: calc(50% - 12px);
    bottom: 30px;
  }
  .material-icons{
    color: $white;
    font-size: 1.7em;
    cursor: pointer;
    margin-right: 15px;
    opacity: .6;
    &:hover{
      opacity: 1 !important;
    }
  }
}
.dark-layer{
  background-color: $black;
  opacity: .5;
  width: 100%;
  height: 100%;
}
.default-letter-spacing{
  letter-spacing: $default-letter-spacing;
}
.display-contents{
  display: contents !important;
}
.display-xl-inline-block{
  @media screen and (min-width: 1550px) {
    display: inline-block !important;
  }
}
.display-xl-none{
  @media screen and (min-width: 1550px) {
    display: none;
  }
}
.display-not-xl-none{
  @media screen and (max-width: 1550px) {
    display: none;
  }
}
.fa{
  font-size: 24px !important;
}
.z-index-100{
  z-index: 100 !important;
}
.height-100{
  height: 100% !important;
}
.link-style, .link-style-white, .link-style-white2, .link-style-white3{
  font-weight: normal;
  .material-icons{
    vertical-align: middle;
  }
  &:hover{
    text-decoration: none;
  }
  &:focus{
    text-decoration: none !important;
  }

}
.link-style-white{
  @include links($white, $blue);
}
.link-style-white2{
  @include links($white, #001d3e);
}
.link-style-white3{
  @include links($white, $white);
  &:hover{
    text-decoration: underline;
    span{
      text-decoration: underline;
    }
  }
}
.list-check{
  li{
    margin-bottom: .2rem;
    &:before{
      content: 'check';
      font-family: 'Material Icons';
      margin-right: .8rem;
      vertical-align: middle;
      font-size: $font-size-md;
    }
  }
}
.material-big{
  font-size: 45px;
}
.non-scrollable{
  height: 100vh;
  overflow: hidden;
}
.overflow-hidden{
  overflow: hidden;
}
.position-absolute{
  position: absolute;
}
.position-fixed{
  position: fixed;
}
.separator-white{
  border-top-color: $white !important;
}
.text-justify{
  text-align: justify;
}
.text-shadow{
  text-shadow: 2px 2px 5px $gray-900;
}
.top-0{
  top: 0;
}
.width-100{
  width: 100%;
}
.separate-lines{
  white-space: pre-wrap;
}
.zoom-image{
  height: 100%;
  display: block;
  position: relative;
  @include media-breakpoint-up(lg){
    overflow: hidden;
    img{
      max-width: 100%;
      -moz-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .zoom-image-hover{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
    }
    .zoom-image-hover:hover + img{
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    .card-info{
      .zoom-image-hover-text-1{
        position: absolute;
        bottom: 15px;
        opacity: 0;
        transition-duration: .1s;
        -webkit-transition-duration: .1s;
      }
      .zoom-image-hover-text-2{
        position: absolute;
        bottom: 0;
        margin-bottom: -10px;
        opacity: 0;
        transition-duration: .1s;
        -webkit-transition-duration: .1s;
      }
    }
    .zoom-image-hover:hover ~ .card-info{
      .zoom-image-hover-text-1{
        bottom: 20px;
        opacity: 1;
        transition-duration: .1s;
        -webkit-transition-duration: .1s;
      }
      .zoom-image-hover-text-2{
        margin-bottom: 0;
        opacity: 1;
        transition-duration: .1s;
        -webkit-transition-duration: .1s;
        -webkit-transition-delay: .2s;
        transition-delay: .2s;
      }
    }
    .zoom-image-hover-info{
      margin-bottom: -10px;
      opacity: 0;
      transition-duration: .1s;
      -webkit-transition-duration: .1s;
    }
    .zoom-image-hover:hover ~ .card-info .zoom-image-hover-info{
      margin-bottom: 0;
      opacity: 1;
      transition-duration: .1s;
      -webkit-transition-duration: .1s;
    }
  }
}

// Margin and Padding
.margin-0{
  margin: 0;
}
.margin-right-left-0{
  margin-right: 0;
  margin-left: 0;
}
.margin-right-left-5{
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.margin-right-10{
  margin-right: 10px
}

.margin-left-15{
  margin-left: 15px
}

.margin-top-0{
  margin-top: 0 !important;
}
.margin-top-5{
  margin-top: 5px;
}
.margin-top-10{
  margin-top: 10px;
}
.margin-top-20{
  margin-top: 20px;
}
.margin-top-30{
  margin-top: 30px;
}
.margin-top-35{
  margin-top: 35px;
}
.margin-top-40{
  margin-top: 40px;
}
.margin-top-50{
  margin-top: 50px;
}
.margin-top-100{
  margin-top: 100px;
}
.margin-top-110{
  margin-top: 110px;
}
.margin-top-120{
  margin-top: 120px;
}

.margin-bottom-0{
  margin-bottom: 0 !important;
}
.margin-bottom-5{
  margin-bottom: 5px !important;
}
.margin-bottom-10{
  margin-bottom: 10px !important;
}
.margin-bottom-20{
  margin-bottom: 20px !important;
}
.margin-bottom-40{
  margin-bottom: 40px;
}
.margin-bottom-80{
  margin-bottom: 80px;
}

.padding-0{
  padding: 0 !important;
}
.padding-2{
  padding: 2px !important;
}
.padding-10{
  padding: 10px !important;
}
.padding-20{
  padding: 20px !important;
}
.padding-bottom-0{
  padding-bottom: 0 !important;
}
.padding-bottom-5{
  padding-bottom: 5px !important;
}
.padding-bottom-10{
  padding-bottom: 10px !important;
}
.padding-bottom-20{
  padding-bottom: 20px !important;
}
.padding-left-15{
  padding-left: 15px !important;
}
.padding-right-0{
  padding-right: 0 !important;
}
.padding-right-15{
  padding-right: 15px !important;
}
.padding-top-0{
  padding-top: 0 !important;
}
.padding-top-10{
  padding-top: 10px !important;
}
.padding-top-20{
  padding-top: 20px !important;
}
.padding-right-left-0{
  padding-right: 0;
  padding-left: 0;
}
.px-xs-0{
  @include media-breakpoint-down(sm){
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}


// Navbar and Sidebar
header{
  @include shadow();
}
.navbar{
  padding: .6rem .4rem;
  .nav-item{
    .nav-link{
      margin-top: .3rem;
      .material-icons{
        font-size: 1.1rem;
        padding: 2px;
        margin-right: 1px;
      }
    }
    .nav-link-top{
      @media (max-width: 1260px){
        padding: .5rem !important;
      }
    }
  }
  .navbar-brand{
    padding-left: 1.2rem;
    margin-right: 0;
  }
  .navbar-left-group{
    margin-right: 14px;
    left: 7px;
    position: absolute;
    top: 14px;
    display: inherit;
    .navbar-toggler {
      border: none;
      background: transparent !important;
      &:hover {
        background: transparent !important;
      }
      .icon-bar {
        width: 22px;
        transition: all 0.2s;
        display: block;
        height: 2px;
        border-radius: 1px;
        margin-top: 4px;
        background-color: #888;
      }
      .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
      .middle-bar {
        opacity: 0;
      }
      .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
      &.collapsed{
        .top-bar {
          transform: rotate(0);
        }
        .middle-bar {
          opacity: 1;
        }
        .bottom-bar {
          transform: rotate(0);
        }
      }
    }
  }
  .navbar-right-group{
    margin-left: 14px;
    right: 7px;
    position: absolute;
    top: 14px;
    display: inherit;
    .material-icons{
      margin-top: 3px;
    }
  }
  .navbar-full-menu{
    .nav-item .nav-link{
      padding: .3rem 0;
    }
    .navbar-full-menu-cont-1{
      margin: 40px auto 20px auto;
      padding: 30px 0;
      border-top: 1px solid $gray-400;
      border-bottom: 1px solid $gray-400;
      ul{
        margin-bottom: 0;
      }
      @include media-breakpoint-down(sm){
        margin-top: 10px;
        padding: 5px 0;
      }
    }
  }
  /*.navbar-toggler {
    margin-right: 14px;
    right: 5px;
    position: absolute;
    top: 15px;
    border: none;
    background: transparent !important;
    &:hover {
      background: transparent !important;
    }
    .icon-bar {
      width: 22px;
      transition: all 0.2s;
      display: block;
      height: 2px;
      border-radius: 1px;
      margin-top: 4px;
      background-color: #888;
    }
    .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }
    .middle-bar {
      opacity: 0;
    }
    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }
    &.collapsed{
      .top-bar {
        transform: rotate(0);
      }
      .middle-bar {
        opacity: 1;
      }
      .bottom-bar {
        transform: rotate(0);
      }
    }
  }*/
  /*.collapse.show{
    padding-bottom: 20px;
    .nav-bt{
      margin-top: 20px;
    }
  }*/
}
.navbar-top-big{
  .nav-item{
    &.active, &:hover{
      .nav-link{
        color: $color-orange !important;
      }
    }
  }
  @include media-breakpoint-up(md){
    .navbar-full-menu{
      display: none;
    }
    &:hover{
      .navbar-full-menu{
        display: block;
      }
    }
  }
}
.navbar-top-small{
  padding: .5rem .7rem;
  .nav-item-separator-r{
    border-right: 1px solid $gray-500;
  }
  .nav-item-separator-l{
    border-left: 1px solid $gray-500;
  }
  .nav-item{
    a{
      padding-top: .1rem !important;
      padding-bottom: .1rem !important;
    }
    &:hover{
      .nav-link{
        color: $gray-400 !important;
      }
    }
    .nav-link{
      margin-top: 0;
    }
  }
}
.navbar-expand-xl .navbar-nav .nav-link,  .navbar-expand-xl .navbar-nav .nav-bt,
.navbar-expand .navbar-nav .nav-link,  .navbar-expand-xl .navbar-nav .nav-bt{
  padding-right: 1rem;
  padding-left: 1rem;
}
.overlay{
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
}
.sidebar{
  width: 550px;
  background-color: $white;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 9999;
  overflow-x: hidden;
  @include media-breakpoint-down(sm){
    width: 100%;
  }
  .sidebar-header{
    position: fixed;
    width: 550px;
    background-color: $white;
    &.bg-transparent-50 {
      opacity: 50%;
    }
    z-index: 1;
    height: 80px;
    top: 0;
    padding: 1rem;
    .close{
      position: absolute;
      top: 20px;
      right: 20px;
      text-shadow: none;
      opacity: 1;
      @include media-breakpoint-down(sm){
        left: 20px;
      }
      .material-icons{
        font-size: 40px;
        color: $gray-600;
      }
    }
    @include media-breakpoint-down(sm){
      border-bottom: 1px solid $gray-200;
      width: 100%;
    }
  }
  .side-header-transparent{
    background: linear-gradient(to top,rgba(0,0,0,0),$black);
    .close{
      left: inherit;
      @include media-breakpoint-down(sm){
        right: inherit;
        left: 20px;
      }
      .material-icons{
        color: $white;
      }
    }
    @include media-breakpoint-down(sm){
      border-bottom: none;
    }
  }
  .sidebar-body{
    padding: 3rem 2.5rem 1.5rem 2.5rem;
    &.no-padding {
      padding: 0;
    }
    .sidebar-item{
      a{
        border-bottom: 1px solid $gray-200;
        padding: 1rem 1rem 0 1rem;
        .material-icons{
          margin-top: 1.8rem;
        }
      }
      &:last-child a{
        border-bottom: none;
      }
      &:hover{
        background-color: $gray-100;
        @include shadow;
        a{
          border-bottom: 1px solid $gray-100;
        }
      }
    }
    @include media-breakpoint-down(sm){
      width: 100%;
      padding: 3rem 1.5rem 1.5rem 1.5rem;
    }
  }
  .sidebar-footer{
    position: fixed;
    width: 550px;
    background-color: $color-orange;
    color: white;
    text-align: center;
    z-index: 1;
    bottom: 0;
    padding: 1rem;
    @include media-breakpoint-down(sm){
      /*width: 100%;*/
    }
  }
}
.slideout-panel {
  .slideout-panel-bg {
    background-color: rgba(0,0,0,.9) !important;
  }
  .slideout-wrapper .slideout-bg-gray {
    background-color: #f8f9fa !important
  }
}
.fixed-spy{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: auto;
  .fixed-element{
    position: -webkit-sticky;
    position: sticky;
    top: 10rem;
    margin-top: 2rem;
    z-index: 2;
  }
}

// Map Sidebar Menu
.map-sidebar-full, #map-sidebar{
  .slideout-menu{
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
    background-color: $white;

  }
  .slideout-menu-left{
    /*left: 0;*/
  }
  .slideout-menu-right{
    right: 0;
  }
  .slideout-panel{
    color: white;
    position: relative;
    z-index: 1;
    will-change: transform;
    min-height: 550px;
  }
  .toggle-button{
    position: absolute;
    top: calc(50% - 37px);
    left: 0;
    z-index: 1;
    height: 55px;
    width: 45px;
    background: #000;
    display: inline-block;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    border: #000;
    cursor: pointer;
    .material-icons{
      color: #fff;
      position: absolute;
      top: 13px;
      left: 4px;
      font-size: 28px;
      transition: 0.70s;
      -webkit-transition: 0.30s;
      -moz-transition: 0.30s;
      -ms-transition: 0.30s;
      -o-transition: 0.30s;
    }
  }
}
#map-sidebar{
  .slideout-menu{
    width: 300px;
    height: 550px;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  }
  .map-sidebar-header{
    width: 100%;
    background: $white;
    top: 0;
    border-bottom: 1px solid $gray-200;
  }
}
.map-sidebar-full{
  .map-sidebar-full-body-2{
    padding: 0 2.5rem 1.5rem 2.5rem;
    .sidebar-item a{
      border-bottom: 1px solid #e9ecef;
      padding: 1rem 1rem 0 1rem;
      .material-icons {
        margin-top: 2.8rem;
      }
    }
    @include media-breakpoint-down(sm){
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
  }
  .slideout-menu{
    width: 510px;
    height: calc(100vh - 100px);

    #v-pills-tab-destination, #v-pills-tab-hotel{
      width: 60px;
      padding-top: 20px;
      .nav-link{
        color: $gray-600;
        background: none;
        &.active{
          color: $blue;
        }
      }
    }
    #v-pills-tabContent-destination, #v-pills-tabContent-hotel{
      width: calc(100% - 60px);
      border-left: 1px solid $gray-400;
    }
  }
}
.slideout-open #map-sidebar .slideout-panel, .slideout-open #map-sidebar-full .slideout-panel{
  overflow: hidden !important;
}
.slideout-open #map-sidebar .slideout-menu, .slideout-open #map-sidebar-full .slideout-menu{
  display: block !important;
}
.slideout-open #map-sidebar .toggle-button .material-icons, .slideout-open #map-sidebar-full .toggle-button .material-icons{
  transition: 0.30s;
  -webkit-transition: 0.30s;
  -moz-transition: 0.30s;
  -ms-transition: 0.30s;
  -o-transition: 0.30s;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

// Lateral Menu
.lateral-menu-jump{
  //position: fixed;
  width: 265px;
  @include media-breakpoint-down(lg){
    width: 225px;
  }
  li{
    padding: .5rem 1rem;
    border-bottom-left-radius: .25rem!important;
    border-top-left-radius: .25rem!important;
    a{
      cursor: pointer;
      .material-icons{
        margin-right: 1rem;
      }
      &:hover{
        color: $blue !important;
      }
    }
    &:last-of-type{
      margin-bottom: 20px;
    }
  }
  li.active{
    background-color: $blue;
    position: relative;
    width: calc( 100% - 15px );
    @include media-breakpoint-up(lg) {
      &:before{
        content: "";
        position: absolute;
        right: -20px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 20px solid $blue;
        border-top: 22px solid transparent;
        border-bottom: 20px solid transparent;
      }
    }
    a{
      color: $white;
      &:hover{
        color: $white !important;
      }
    }
  }
}

// Containers and Sections
.bg-white{
  background-color: $white;
}
.bg-blue{
  background-color: $blue;
}
.section-explain{
  margin: 3rem auto;
  div{
    margin-bottom: 3rem;
    padding-left: 20px;
    padding-right: 20px;
    .icon{
      margin-bottom: 1.2rem;
    }
    h3{
      margin-bottom: 1.6rem;
    }
  }

}
.section-style{
  padding: 3.5% 1rem;
  overflow-x: hidden;
}
.section-style-white-lateral{
  @include media-breakpoint-down(sm){
    margin: auto -15px;
    .margin-right-left-5{
      margin: 0 !important;
    }
  }
  @include media-breakpoint-up(sm){
    margin-left: calc(-100vw / 2 + 540px / 2 - 15px);
    margin-right: calc(-100vw / 2 + 540px / 2 - 5px);
  }
  @include media-breakpoint-up(md){
    margin-left: calc(-100vw / 2 + 720px / 2 - 15px);
    margin-right: calc(-100vw / 2 + 720px / 2 - 5px);
  }
  @include media-breakpoint-up(lg){
    margin-left: calc(-100vw / 2 + 960px / 2 - 33.3% - 15px);
    margin-right: calc(-100vw / 2 + 960px / 2 - 5px);
  }
  @include media-breakpoint-up(xl){
    margin-left: calc(-100vw / 2 + 1140px / 2 - 33.3% - 15px);
    margin-right: calc(-100vw / 2 + 1140px / 2 - 5px);
  }
  .container{
    .row.justify-content-end{
      @include  media-breakpoint-down(sm){
        margin: auto -15px;
      }
      @include  media-breakpoint-up(sm){
        margin: auto 0 auto 10px;
      }
    }
  }

}
.section-style-big{
  padding: 7% 1rem;
  .section-style-big-title{
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .section-style-big-btn{
    margin-top: 3.5rem;
    a{
      margin: .5rem 0;
    }
  }
}
.section-style-top{
  padding-top: 3.5%;
}
.section-title{
  margin: 3rem auto;
}
.top-container-margin{
  margin-top: 100px;
}
.dragabble-list{
  li{
    border-bottom: 1px solid $gray-200;
    padding: 1rem 0;
    &:last-child li{
      border-bottom: none;
    }
    .drag-handle{
      &:hover {
        cursor: grab;
      }
      &:active {
        cursor: grabbing;
      }
    }
  }
}

// Videos
.video-background, .jumbotron-big, .jumbotron-small, .jumbotron-extra-small{
  background: $black;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  right: 0;
  bottom: 0;
  left: 0;
  .position-absolute{
    padding: 3.5% 1.5rem;
    .jumbo-info{
      margin-top: 5rem;
      z-index: 2;
      .separator-white{
        margin: 2rem auto;
      }
      .font-lg{
        @include media-breakpoint-down(sm) {
          font-size: $font-size-sm;
        }
      }
      .font-xl{
        @include media-breakpoint-down(sm) {
          font-size: $font-size-lg;
        }
      }
    }
  }
}
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}

.video-cont{
  position: relative;
  margin-top: 55px;
  @include media-breakpoint-down(sm){
    margin-top: 110px;
  }
  .video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


// Sliders and Jumbotron
.jumbotron-big{
  border-radius: 0;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-size: cover !important;
  background-position: center !important;
}
.jumbotron-small{
  border-radius: 0;
  height: 400px;
  padding: 0;
  margin: 0;
  background-size: cover !important;
  background-position: center !important;
}
.jumbotron-extra-small{
  border-radius: 0;
  height: 300px;
  padding: 0;
  margin: 0;
  background-size: cover !important;
  background-position: center !important;
}

// Cards
.card-inside-cont{
  padding-right: 0;
  padding-left: 0;
  margin: 4px 2px 0;
  overflow: hidden;
  img{
    width: 100%;
  }
  .card-info{
    height: 145px;
    width: 100%;
    position: absolute;
    margin: 0;
    padding: 1.6rem;
    bottom: 0;
    background: linear-gradient(to bottom,rgba(0,0,0,0),$black);
  }
}
.card-inside-cont-insta{
  padding-right: 2px;
  padding-left: 2px;
  img{
    width: 100%;
  }
  .card-info{
    height: 145px;
    width: 100%;
    position: absolute;
    margin: 0;
    padding: 1.6rem;
    bottom: 0;
    background: linear-gradient(to bottom,rgba(0,0,0,0),$black);
  }
  &:hover{
    cursor: pointer;
  }
}

// Boxes
.box-element{
  height: calc(100% - 30px);
  margin-bottom: 30px;
  .box-element-info{
    padding: 1.6rem;
    p{
      margin-bottom: 0;
    }
  }
  .box-element-link{
    padding: 1rem 0;
    border-top: 1px solid $gray-200;
  }
}
.box-element-lateral{
  margin-bottom: 30px;
  .box-element{
    margin-bottom: 0;
    height: 100%;
  }
  @include media-breakpoint-up(md) {
    .box-element-lateral-right{
      padding-right: 0;
    }
    .box-element-lateral-left{
      padding-left: 0;
    }
  }
  .box-element-lateral-image{
    height: 100%;
    width: 100%;
    min-height: 200px;
    background-size: cover;
    background-position: center;
  }
  .box-element-lateral-right{
    .box-element-lateral-image{
      a{
        @include rounded-ind($round, 0, 0, $round);
        @include media-breakpoint-down(sm) {
          @include rounded-ind($round, $round, 0, 0);
        }
        img{
          @include rounded-ind($round, 0, 0, $round);
          @include media-breakpoint-down(sm) {
            @include rounded-ind($round, $round, 0, 0);
          }
        }
      }
    }
  }
  .box-element-lateral-left{
    .box-element{
      @include rounded-ind(0, $round, $round, 0);
      @include media-breakpoint-down(sm) {
        @include rounded-ind(0, 0, $round, $round);
      }
    }
    .box-element-lateral-image{
      a{
        @include rounded-ind(0, $round, $round, 0);
        @include media-breakpoint-down(sm) {
          @include rounded-ind(0, 0, $round, $round);
        }
        img{
          @include rounded-ind(0, $round, $round, 0);
          @include media-breakpoint-down(sm) {
            @include rounded-ind($round, $round, 0, 0);
          }
        }
      }
    }
  }
  .box-element-image{
    .zoom-image{
      @include rounded-ind($round, $round, 0, 0);
      img{
        @include rounded-ind($round, $round, 0, 0);
      }
    }
  }
}
.box-form{
  padding: 2.2rem;
  margin-bottom: 30px;
  @include media-breakpoint-down(sm){
    padding: 20px;
  }
}

// Quotes and Partners
#quotes-box, #partners-box{
  margin: 4px 2px 0;
}
#quotes-box{
  background-color: $color-blue;
  .rounded-circle{
    background-color:rgba(255, 255, 255, 0.3);
    padding: .2rem;
    width: fit-content;
    margin: 1.5rem auto;
  }
}
#partners-box{
  background-color: $gray-900;
  .carousel-inner{
    img{
      margin-bottom: 2rem;
      height: 87px;
      @include media-breakpoint-down(xs){
        height: auto;
        width: 100%;
        max-height: 87px;
      }
    }
  }
}

// App Section
#app-promo{
  margin: 4px 2px 0 2px;
  background: url("/assets/bg/UJV-app.jpg") no-repeat center;
  background-size: cover;
  text-align: left;
  @include media-breakpoint-up(xl) {
    .section-style-big-title{
      h2, p{
        color: $white;
      }
    }
    .section-style-big-description{
      h3, p{
        color: $white;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    background-image: none;
    /*border-bottom: 1px solid $gray-400;*/
    .section-style-big-image{
      img{
        width: 80%;
      }
    }
    .section-style-big-btn{
      .btn-light{
        color: $white;
        background-color: $gray-300;
        border-color: $gray-300;
        &:hover, &:active{
          color: $white;
          background-color: $gray-400;
          border-color: $gray-400;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .section-style-big-image{
      img{
        width: 60%;
      }
    }
  }
}
#app-small-promo{
  .section-style-big-title{
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .section-style-big-image{
    img{
      width: 80%;
    }
  }
  .section-style-big-btn{
    margin-top: 3.5rem;
    a{
      margin: .5rem 0;
    }
    .btn-light{
      color: $white;
      background-color: $gray-300;
      border-color: $gray-300;
      &:hover, &:active{
        color: $white;
        background-color: $gray-400;
        border-color: $gray-400;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .section-style-big-image{
      img{
        width: 60%;
      }
    }
  }
}

// Virtual Tour
#virtual-tour{
  margin: 4px 2px 0;
  background-color: $color-blue-black;
  .section-style-big-image{
    img{
      margin-top: 16%;
      @include media-breakpoint-only(lg) {
        margin-top: 25%;
      }
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }
  }
}

// Awards and Instagram
#awards{
  margin: 4px 2px 0;
  background-color: $color-blue;
  img{
    margin: 1rem 0;
  }
}
#instagram-col-1{
  margin-top: 4px;
  .col-md-6{
    margin-top: 4px;
  }
  img{
    width: 100%;
    height: 100%;
  }
}
#instagram-col-2{
  margin-top: 4px;
  overflow: hidden;
  img{
    width: 100%;
  }
  #share-exp-cont{
    margin-top: 4px;
    padding: 0 2px;
    .col-12{
      background-color: $gray-900;
    }
  }
  @media (max-width: 1530px) {
    display: none;
  }
}

// Hotel
.amenities-list{
  padding: 2rem;
  background-color: $white;
  margin-top: 2rem !important;
  ul li{
    margin-bottom: 1rem;
    .material-icons{
      margin-right: 1rem;
      float: left;
      height: 100%;
    }
    span{
      display: flex;
    }
  }
}
.vue-star-rating{
  .vue-star-rating-pointer{
    margin-right: 3px !important;
    .vue-star-rating-star{
      height: 21px;
      width: 21px;
    }
  }
}
/*.lateral-main-cont{
  padding-left: 2.5rem;
  @include media-breakpoint-down(xs) {
    padding-left: 4.5rem;
  }
}*/

// Festive
.accordion{
  .card{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $gray-300 !important;
    .card-header{
      padding: 0;
      background-color: transparent;
      border: none;
      button{
        padding-left: 0 !important;
      }
    }
  }

}

// Maps
.google-map{
  height: 600px;
}

// Map full
#map-page{
  margin-bottom: -48px;
}

// Gallery
.gallery{
  margin-top: 3rem;
  div[class^="col-"]{
    margin-bottom: 30px;
  }
}

// Sliders
.carousel{
  .carousel-indicators{
    position: relative;
    margin-top: 3rem;
  }
  .carousel-indicators-gray{
    li{
      background-color: $gray-400;
      opacity: .5;
    }
    li.active{
      opacity: 1;
    }
  }
  .carousel-inner{
    .carousel-item{
      @include media-breakpoint-up(md) {
        padding: 0 15%;
      }
    }
  }
  .carousel-inner-no-controls{
    .carousel-item{
      padding: 0;
    }
  }
  .carousel-control-prev, .carousel-control-next{
    width: 50px;
    .material-icons{
      color: $gray-800;
      font-size: 2rem;
    }
  }
  .carousel-control-prev{
    left: -40px;
  }
  .carousel-control-next{
    right: -40px;
  }
}

// Modals
.v--modal{
  @include media-breakpoint-down(sm){
    left: inherit !important;
    width: auto !important;
  }
}
.modal-dialog{
  @include media-breakpoint-down(sm){
    margin: 0;
    max-width: 100%;
  }
}
.modal-content{
  border: 0;
  @include media-breakpoint-down(sm){
    height: 100vh;
    overflow: scroll;
    border-radius: 0;
  }
  .modal-header{
    position: absolute;
    width: 100%;
    background: $white;
    z-index: 1;
    height: 80px;
    top: 0;
    border: none;
    border-radius: 0;
    @include media-breakpoint-down(sm){
      position: fixed;
      left: 0;
    }
    .close{
      position: absolute;
      top: 20px;
      right: 20px;
      text-shadow: none;
      opacity: 1;
      @include media-breakpoint-down(sm){
        left: 0;
        right: auto;
      }
      .material-icons{
        font-size: 40px;
        color: $gray-600;
      }
    }
    @include media-breakpoint-down(sm){
      border-bottom: 1px solid $gray-200;
    }
  }
  .modal-header-gradient{
    background: linear-gradient(to top,rgba(0,0,0,0),$black);
    .close{
      .material-icons{
        color: $white;
      }
    }
    @include media-breakpoint-down(sm){
      border-bottom: none !important;
    }
  }
  .modal-body{
    padding: 0;
    .modal-body-content{
      padding: 4rem 2.5rem 1.5rem 2.5rem;
      @include media-breakpoint-down(sm){
        padding: 3rem 1.5rem 1.5rem 1.5rem;
      }
    }
  }
}
.modal-100{
  padding-right: 0 !important;
  .modal-dialog{
    max-width: 100%;
    margin: 0;
    height: 100%;
    .modal-header{
      .close .material-icons{
        color: $white !important;
      }
      @include media-breakpoint-down(sm){
        border-bottom: none !important;
      }
    }
    .modal-content{
      height: 100%;
      background-color: transparent;
      .modal-body-content{
        padding: 3rem 0 1.5rem 0 !important;
      }
    }
  }
}
.modal-backdrop.show{
  opacity: .9 !important;
}

// Dialogs
.vue-dialog{
  padding: 3rem 2.5rem 1.5rem !important;
  @include media-breakpoint-down(sm){
    padding: 3rem 1.5rem 1.5rem !important;
    height: 100vh !important;
  }
  .dialog-content{
    padding: 0 !important;
    .dialog-c-title{
      margin: 3rem auto;
      letter-spacing: 0.1rem;
      font-size: 1rem;
      font-weight: 700 !important;
      text-transform: uppercase !important;
      text-align: center !important;
      color: rgb(52, 58, 64);
      line-height: 1.5;
    }
    .dialog-c-text{
      font-size: 1rem !important;
    }
  }
  .vue-dialog-buttons{
    border: none !important;
    margin: 40px 0 !important;
    -webkit-box-pack: center!important;
    justify-content: center!important;
    .btn{
      flex: inherit !important;
      margin: 0 15px;
    }
  }
}

// Horizontal Filter
.section-filter{
  margin-right: -15px;
  margin-left: -15px;
  .section-filter-cont{
    padding: 2.4rem 0;
  }
}

// Tabs Custom
.tabs-custom{
  li a{
    color: $gray-300;
    &.active{
      color: $blue;
    }
  }
}
.pill-tabs-custom .nav-item{
  .btn-light.active{
    color: #fff !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
  }
  .btn-light{
    background-color: #e2e6ea !important;
  }
}

// Log in / Sign in Modal Form
#loginSignTab{
  border: none;
  .nav-item{
    width: 50%;
    .nav-link{
      border: none;
      border-radius: 0;
      padding: 1rem;
      color: $white;
      &.active{
        color: $gray-600;
      }
    }
  }
}
#loginSignTabContent{
  background-color: $white;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
}

// Tables
.table th, .table td{
  padding: .7rem .7rem .2rem .7rem
}

// Create an Account Steps
.bs-stepper{
  .bs-stepper-header{
    .step{
      .material-icons{
        font-size: 33px;
        color: #E6E6E6
      }
      .bs-stepper-circle{
        background-color: #E6E6E6;
      }
      .bs-stepper-label{
        color: #E6E6E6;
      }
    }
    .step.active{
      .material-icons{
        color: #3ED2A0
      }
      .bs-stepper-circle{
        background-color: #3ED2A0;
      }
      .bs-stepper-label{
        color: #3ED2A0;
      }
    }
    .line{
      @include media-breakpoint-down(xs){
        margin-bottom: 40px;
      }
    }
  }
  .step-trigger{
    display: inline !important;
  }
}

// Footer
footer{
  margin-top: 3rem;
  #footer-contact{
    border-top: 1px solid $gray-300;
    #footer-chat, #footer-phone{
      padding: 2rem;
      cursor: pointer;
      @include links-div(transparent, $gray-200);
      a {
        color: $gray-600;
        text-decoration: none;
      }
    }
    #footer-chat{
      border-right: 1px solid $gray-300;
    }
    .material-icons{
      margin-bottom: 1.2rem;
    }
  }
  #footer-newsletter{
    &, .inside-submit{
      background-color: $gray-700;
    }
    button{
      cursor: pointer;
      .loader{
        width: 30px;
      }
    }
  }
  #footer-info{
    background-color: $gray-900;
    ul{
      li{
        margin-bottom: .5rem;
        a{
          @include links($white, $blue);
        }
      }
    }
  }
  #footer-copyright{
    background-color: $gray-900;
    .copyright{
      margin-top: 1rem
    }
  }
}
.help-bubble{
  position: fixed;
  bottom: 70px;
  right: 30px;
  z-index: 16;
  .bubble-cont{
    width: 370px;
    @include media-breakpoint-down(xs){
      width: 320px;
    }
    margin-bottom: 10px;
    h3{
      padding: 20px 0;
    }
    ul{
      li{
        border-bottom: 1px solid rgba(255,255,255,0.3);
        padding: 10px 0;
        a{
          .material-icons{
            margin-right: 10px;
          }
        }
      }
    }
  }
  .bubble-icon{
    .material-icons{
      font-size: 45px;
      cursor: pointer;
      &:hover{
        color: #0056b3;
      }
    }
  }
}

// Alert
.alert{
  padding: 1.6rem;
  border: none;
  max-width: 500px;
  .material-icons{
    font-size: 50px;
    @include media-breakpoint-down(sm){
      font-size: 40px;
    }
  }
  .btn-bucket{
    .material-icons{
      font-size: 24px;
    }
  }
}
.alert-warning{
  background-color: $color-yellow;
}

// Carousel
.carousel__list{
  li{
    img{
      @include rounded;
      margin-top: 40px !important;
    }
  }
}

// Social Media Dark Icons
.social-media-dark-icons{
  .link-style-white{
    color: $gray-400 !important;
    &:hover{
      color: #007bff !important;
    }
  }
}

// Wizehire
#wizehire-jobroll-container{
  width: auto !important;
  border: none !important;
  padding: 0 !important;
  font-family: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  line-height: inherit !important;
  background: none !important;
  .wh-job-row{
    a{
      font-weight: 700 !important;
      font-size: 1rem !important;
      color: #007bff !important;
      margin-bottom: 30px;
      display: block;
      &:hover{
        color: #0056b3 !important;
      }
    }
    div{
      span{
        color: inherit !important;
        font-weight: bold;
      }
    }
    div + div{
      margin-top: 10px !important;
    }
  }
  .wh-job-row + div{
    display: none;
  }
}

#video-hotel-info {
  width: 0 !important;
  opacity: 0 !important;
}
