$snotify-success: #c8e6c9 !default;
$snotify-info: #bbdefb !default;
$snotify-warning: #ffccbc !default;
$snotify-error: #ffcdd2 !default;
$snotify-async: $snotify-info !default;

$snotify-icons: -generate-icons(
  (
    error: $snotify-error,
    warning: $snotify-warning,
    info: $snotify-info,
    success: $snotify-success,
    async: $snotify-async
  )
) !default;

.snotify-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  line-height: 0;
  transform: translate(0, -50%);
  max-height: 48px;
  max-width: 48px;
  width: 100%;
  height: 100%;
}

.snotify-icon--error {
  background-image: url('#{map-get($snotify-icons, error)}');
}

.snotify-icon--warning {
  background-image: url('#{map-get($snotify-icons, warning)}');
}

.snotify-icon--info {
  background-image: url('#{map-get($snotify-icons, info)}');
}

.snotify-icon--success {
  background-image: url('#{map-get($snotify-icons, success)}');
}

.snotify-icon--async {
  background-image: url('#{map-get($snotify-icons, async)}');
  animation: async 3s infinite linear;
  transform-origin: 50% 50%;
}
