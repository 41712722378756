// Fonts
$font-size-md: 1rem !default;
$font-size-lg: 1.6rem !default;
$font-size-xl: 3.2rem !default;

// Colors
$color-orange: #ffad56;
$color-orange-dark: #ff9956;
$color-blue-black: #0f0d1d;
$color-blue: #0071bc;
$color-yellow: #FFFDE7;

// Letter spacing
$default-letter-spacing: .1rem;

// Rounded
$round: .25rem;